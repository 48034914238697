<template>
    <ManagerPage />
</template>
<script>

import ManagerPage from '../components/ManagerPage.vue'

export default {
    components: {
        ManagerPage
    },
}
</script>

