<template>
<v-container fluid fill-height>
    <v-card :class="`align-self-stretch ` + ($vuetify.breakpoint.mdAndUp ? `pa-4 ` : `pa-0` )" :flat="$vuetify.breakpoint.mdAndUp ? true : false" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`" width="100%">
        <v-card-title class="px-0">
            <h1>Контакти Юг-Контракт</h1>
        </v-card-title>
        <v-card-subtitle class="px-0 py-4">Якщо у вас виникли питання стосовно взаємодії з нашою компанією, ви можете звернутися до закріплених за вами менеджерів, або їх керівників.</v-card-subtitle>
        <v-card-text class="px-0 py-4"><h2>Менеджери</h2></v-card-text>
        <v-data-iterator :loading="loading" :items="respUsersList" disable-pagination hide-default-footer class="mb-6">
            <template v-slot:default="props">
                <v-row no-gutters>
                    <v-col class="pa-1 pt-0 pb-2 d-flex align-stretch" v-for="item in props.items" :key="item.typhoon_full_id" cols="12" sm="6" md="4" lg="3">
                        <ManagerBlock :item="item" />
                    </v-col>
                </v-row>
            </template>
            <template v-slot:loading>
                <div class="py-10 body-2 grey--text text-center font-weight-light">Зачекайте...</div>
            </template>
        </v-data-iterator>
        <v-card-text class="px-0 py-4"><h2>Керівник Каналу збуту</h2></v-card-text>
        <v-data-iterator :loading="loading" :items="scRespUsersList" disable-pagination hide-default-footer class="mb-6">
            <template v-slot:default="props">
                <v-row no-gutters>
                    <v-col class="pa-1 pt-0 pb-2" v-for="item in props.items" :key="item.typhoon_full_id" cols="12" sm="6" md="4" lg="3">
                        <ManagerBlock :item="item" />
                    </v-col>
                </v-row>
            </template>
            <template v-slot:loading>
                <div class="py-10 body-2 grey--text text-center font-weight-light">Зачекайте...</div>
            </template>
        </v-data-iterator>
        <v-card-text class="px-0 py-4"><h2>Додаткові контакти</h2></v-card-text>
        <v-data-iterator :loading="loading" :items="additionalManagers" disable-pagination hide-default-footer class="mb-6">
            <template v-slot:default="props">
                <v-row no-gutters>
                    <v-col class="pa-1 pt-0 pb-2" v-for="item in props.items" :key="item.typhoon_full_id" cols="12" sm="6" md="4" lg="3">
                        <ManagerBlock :item="item" />
                    </v-col>
                </v-row>
            </template>
            <template v-slot:loading>
                <div class="py-10 body-2 grey--text text-center font-weight-light">Зачекайте...</div>
            </template>
        </v-data-iterator>
    </v-card>
</v-container>
</template>

<script>
import {
    mapActions
} from 'vuex'
import ManagerBlock from './ManagerBlock.vue'

export default {
    components: {
        ManagerBlock
    },
    data: () => ({
    }),
    props: {},
    methods: {
        ...mapActions(['getManagersList']),
    },
    created() {
        this.getManagersList()
    },
    computed: {
        respUsersList() {
            return this.$store.state.main.managersList.respUsersList
        },
        scRespUsersList() {
            return this.$store.state.main.managersList.scRespUsersList
        },
        additionalManagers() {
            return this.$store.state.main.managersList.respUsersAdd
        },
        loading() {
            return this.$store.state.main.loading
        },
    }
}
</script>

<style>

</style>
