<template>
<v-card width="100%" class="pa-4">
    <v-list-item class="px-0">
        <!-- {{item}} -->
        <v-list-item-avatar class="align-self-start">
            <v-img v-if="item.managerPhoto" :src="'https://intranet.yugcontract.ua'+item.managerPhoto"></v-img>
            <v-icon v-if="!item.managerPhoto" x-large color="grey lighten-2">mdi-account-circle</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
            <h3>{{item.name}}</h3>
            <div class="text-lowercase body-2">
                <v-icon small class="pr-2">mdi-sitemap</v-icon>
                <span v-if="item.wholesale_role == 'master'">Основний менеджер</span>
                <span v-if="item.wholesale_role == 'slave'">Заступник</span>
                <span v-if="item.wholesale_role !== 'master' && item.wholesale_role !== 'slave'">{{item.comment}}</span>
            </div>
        </v-list-item-content>
    </v-list-item>
    <div>
        <v-icon small class="pr-2">mdi-phone</v-icon>
        <a v-if="item.mobilephone" :href="`tel:`+item.mobilephone">{{item.mobilephone}}</a>
        <span v-else> - </span>
    </div>
    <div>
        <v-icon small class="pr-2">mdi-phone</v-icon>
        <span v-if="item.cityphone">{{item.cityphone}}</span>
        <span v-else> - </span>
    </div>
    <div>
        <v-icon small class="pr-2">mdi-mail</v-icon><a :href="`mailto:`+item.email">{{item.email}}</a>
    </div>
    <div>
        <v-icon small class="pr-2" color="blue">mdi-skype</v-icon>
        <a v-if="item.skype" :href="`skype:`+item.skype" class="blue--text">{{item.skype}}</a>
        <span v-else> - </span>
    </div>
</v-card>
</template>

<script>
export default {
    components: {},
    data: () => ({}),
    props: {
        item: Object
    },
    methods: {},
    created() {},
    computed: {}
}
</script>

<style>

</style>
